<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-table
        ref="table"
        tableId="table"
        :title="title"
        :columnSetting="false"
        :isFullScreen="false"
        :isExcelDown="true"
        :columns="grid.columns"
        :data="grid.data"
        @rowClick="rowClick"
      >
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <q-form ref="editForm">
        <c-card title="LBLDETAIL" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable"
                label="LBLREG"
                icon="add"
                @btnClicked="addInfo" />
              <c-btn
                v-if="editable && deleteable"
                label="LBLREMOVE"
                icon="delete"
                @btnClicked="deleteInfo" />
              <c-btn
                v-if="editable && saveable"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="datadetail"
                :mappingType="mappingType"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveInfo"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-3">
              <c-datepicker
                :editable="editable && dataeditable"
                :required="true"
                label="실시일자"
                name="execDt"
                v-model="datadetail.execDt"
              />
            </div>
            <div class="col-12">
              <c-text
                :editable="editable && dataeditable"
                :required="true"
                label="업무내용"
                name="remarks"
                v-model="datadetail.remarks">
              </c-text>
            </div>
            <div class="col-12">
              <c-upload
                :attachInfo="attachInfo2"
                :editable="editable && dataeditable"
                label="관련 자료"
                @finishTaskKeyUpdate="finishTaskKeyUpdate">
              </c-upload>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'govScheduleHistory',
  props: {
    param: {
      type: Object,
      default: () => ({
        govScheduleMstId: '',
      }),
    },
    data: {
      type: Object,
      default: () => ({
        govScheduleMstId: '',  // 대관업무 마스터 일련번호
        govScheduleTypeCd: null,  // 대관업무구분
        plantCd: null,  // 사업장코드
        govScheduleName: '',  // 대관업무명
        govScheduleCycleCd: null,  // 법정 주기
        remarks: '',  // 내용
        useFlag: 'Y',  // 사용여부
        inspType: null,  // 검사-종류
        relatedLawsCd: null,  // 검사-관련법규
        inspTarget: '',  // 검사-대상 시설
        contStatus: null,  // 계약-상태
        contType: null,  // 계약-구분
        contVendor: '',  // 계약-업체
        users: [],
        deleteUsers: [],
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'execDt',
            field: 'execDt',
            label: '실시일자',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '업무내용',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'GOV_SCHEDULE_HISTORY',
        taskKey: '',
      },
      datadetail: {
        govScheduleHistoryId: '',  // 대관업무 이력 일련번호
        govScheduleMstId: '',  // 대관업무 마스터 일련번호
        execDt: '',  // 실시일자
        execUserId: '',  // 대관업무 실시자
        remarks: '',  // 내용
      },
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      detailUrl: '',
      saveable: false,
      deleteable: false,
      dataeditable: false,
      editable: true,
      updateMode: false,
      isSave: false,
      mappingType: 'POST',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    title() {
      return '대관업무 이력 ('+this.data.govScheduleTypeName+' > '+this.data.govScheduleName+')'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // url setting
      this.listUrl = selectConfig.env.gov.history.list.url;
      this.detailUrl = selectConfig.env.gov.history.get.url;
      this.saveUrl = transactionConfig.env.gov.history.save.url;
      this.deleteUrl = transactionConfig.env.gov.history.delete.url;
      // code setting
      // list setting
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    finishTaskKeyUpdate() {
      Object.assign(this.$data.attachInfo2, this.$options.data().attachInfo2);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {govScheduleMstId: this.param.govScheduleMstId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.reset();
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.updateMode = true;
      this.dataeditable = true;
      this.$http.url = this.detailUrl;
      this.$http.param = {govScheduleHistoryId: row.govScheduleHistoryId};
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.datadetail = _result.data;
        this.mappingType = 'PUT';
        this.$set(this.attachInfo2, 'taskKey', row.govScheduleHistoryId)
      },
      () => {
      });
    },
    addInfo() {
      this.$set(this.attachInfo2, 'taskKey', null)
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.saveUrl;
      this.mappingType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.datadetail = {
        govScheduleHistoryId: uid(),  // 대관업무 이력 일련번호
        govScheduleMstId: this.param.govScheduleMstId,  // 대관업무 마스터 일련번호
        execDt: this.$comm.getToday(),  // 실시일자
        execUserId: this.$store.getters.user.userId,  // 대관업무 실시자
        remarks: '',  // 내용
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.datadetail = {
        govScheduleHistoryId: '',  // 대관업무 이력 일련번호
        govScheduleMstId: this.param.govScheduleMstId,  // 대관업무 마스터 일련번호
        execDt: '',  // 실시일자
        execUserId: '',  // 대관업무 실시자
        remarks: '',  // 내용
      };
    },
    saveInfo() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if(this.mappingType == 'POST') {
        this.$set(this.attachInfo2, 'taskKey', result.data.govScheduleHistoryId)
        this.$set(this.attachInfo2, 'isSubmit', uid())
      }
      this.getList();
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.datadetail.govScheduleHistoryId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>