var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c("c-table", {
          ref: "table",
          attrs: {
            tableId: "table",
            title: _vm.title,
            columnSetting: false,
            isFullScreen: false,
            isExcelDown: true,
            columns: _vm.grid.columns,
            data: _vm.grid.data,
          },
          on: { rowClick: _vm.rowClick },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "q-form",
          { ref: "editForm" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "LBLDETAIL" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "LBLREG", icon: "add" },
                              on: { btnClicked: _vm.addInfo },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.deleteable
                          ? _c("c-btn", {
                              attrs: { label: "LBLREMOVE", icon: "delete" },
                              on: { btnClicked: _vm.deleteInfo },
                            })
                          : _vm._e(),
                        _vm.editable && _vm.saveable
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.saveUrl,
                                isSubmit: _vm.isSave,
                                param: _vm.datadetail,
                                mappingType: _vm.mappingType,
                                label: "LBLSAVE",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveInfo,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-3" },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          editable: _vm.editable && _vm.dataeditable,
                          required: true,
                          label: "실시일자",
                          name: "execDt",
                        },
                        model: {
                          value: _vm.datadetail.execDt,
                          callback: function ($$v) {
                            _vm.$set(_vm.datadetail, "execDt", $$v)
                          },
                          expression: "datadetail.execDt",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable && _vm.dataeditable,
                          required: true,
                          label: "업무내용",
                          name: "remarks",
                        },
                        model: {
                          value: _vm.datadetail.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.datadetail, "remarks", $$v)
                          },
                          expression: "datadetail.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-upload", {
                        attrs: {
                          attachInfo: _vm.attachInfo2,
                          editable: _vm.editable && _vm.dataeditable,
                          label: "관련 자료",
                        },
                        on: { finishTaskKeyUpdate: _vm.finishTaskKeyUpdate },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }